import React from 'react';

const SideNews = ({ sideNews, onSideNewsClick }) => {
  return (
    <div className="side-news">
      <h3>Ostale vesti</h3>
      <ul>
        {sideNews.map((news, index) => (
          <li key={index} onClick={() => onSideNewsClick(news)}>
            <div className="side-news-item">
              <img src={news.imageUrl} alt="News" className="side-news-image" />
              <div className="side-news-content">
                <h4>{news.title}</h4>
                <p>{news.content}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideNews;
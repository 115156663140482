import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import { faTeletype} from '@fortawesome/free-solid-svg-icons';
import { faEnvelopeCircleCheck} from '@fortawesome/free-solid-svg-icons';



import logic from './logic.svg';
const toolbar = () => {
    return (
        <div class="toolbar">
            <div class="contact-info"> </div>
              <div class="contact-info"><a href="/"><img src={logic} className="App-logic" alt="logo" /></a></div>
              
      
              <div class="contact-info">< FontAwesomeIcon icon={faUserClock}       />            Radno vreme: 08-17h</div>
              <div class="contact-info">< FontAwesomeIcon icon={faEnvelopeCircleCheck } />    office@bigcon.rs</div>
              <div class="contact-info">< FontAwesomeIcon icon={faTeletype } />              069/107-52-80</div>
              <div class="social-icons">
                <a href="#">< FontAwesomeIcon icon={faTiktok} /></a>
                <a href="https://www.facebook.com/profile.php?id=61552656032931">< FontAwesomeIcon icon={faFacebook} /></a>
                <a href="https://www.instagram.com/big.con_srbija/?next=%2F&hl=en">< FontAwesomeIcon icon={faInstagram} /></a>
                <a href="#">< FontAwesomeIcon icon={faLinkedin} /></a>
                <a href="#">< FontAwesomeIcon icon={faYoutube} /></a>
              </div>
            </div>
    )
}
export default toolbar
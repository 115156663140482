import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload} from '@fortawesome/free-solid-svg-icons';

function NavBar () {
    return(
<div className='navbar'>
    <ul >
        <li><Link to='/'>Početna</Link></li>
        <li><Link to='/about'>O nama</Link></li>
        <li><Link to='/services'>Naše usluge</Link></li>
        <li><Link to='/vesti'>Vesti</Link></li>
        <li><Link to='/contact'>Kontakt</Link></li>
        <li><Link className='downloadd' to='/download'>< FontAwesomeIcon icon={faDownload } /> Download </Link></li>
    </ul>
</div>

    )
}
export default NavBar;
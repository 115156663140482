
import ServiceCard from "./ServiceCard";
import '../App.css'
import masinsko from "./slike/masinsko.jpg"
import termo from "./slike/termo.png"
import suva from "./slike/suva.jpg"
import hidro from "./slike/hidro.jpg"
import boja from "./slike/boja.webp"
import sto from "./slike/sto.webp"
import obloge from "./slike/obloge.jpg"
import svasta from "./slike/svasta.jfif"
import vodo from "./slike/vodo.jpg"
import elektro from "./slike/elektro.jfif"





const services = [
   
    {
      title: 'Mašinsko malterisanje',
      description: 'Malterisanje malterima po zelji kupaca gipsanim ili cemetnim raznih renomiranih proizvođača tipa ROFIX, KNAUF, BEKAMENT, MAXIMA.',
      image: masinsko,
    },
    {
      title: 'Termoizolacija i Fasade',
      description: 'Izrada fasada nasim skelama renomiranih proiizvodjaca HELIOS, ZORKA, BEKAMENT, ROFIX, MAXIMA ETC.',
      image: termo,
    },
    {
        title: 'Suva gradnja',
        description: 'Postavljanje sistema spustenih plafona i pregradnih zidova od gips karton ploca po svim standardima i propisima(protivpozarnim, protiv buke, veca opterecenja etc).',
        image: suva,
      },
      {
        title: 'Hidroizolacije',
        description: 'Sve vrste hidrozilacija puput hidroizolacije kupatila, kuhinja, ravnih krovova, temelja, terasa, bazena etc.',
        image: hidro,
      },
      {
        title: 'Elektro radovi',
        description: 'Kompletna elektricna instalacija',
        image: elektro,
      },
      {
        title: 'Bojenje ',
        description: 'Svaka vrsta farabanja spojasnjeg i untrasnjeg sa najnovijm airless aparatima.',
        image: boja,
      },
      {
        title: 'Ugradnja stolarije',
        description: 'Uzimanje mera, skidanje stare stolarije, ugradnja nove sa roletnama i komarnicima po zelji.',
        image: sto,
      },
      {
        title: 'Postavljanje zidnih i podnih obloga',
        description: 'Praesent lacinia turpis id semper efficitur.',
        image: obloge,
      },
      {
        title: 'Ostali završni radovi',
        description: 'Ostali radovi koji nisu spomenuti poput gletovanja, krpljanje , sitnih popravki etc.',
        image: svasta,
      },
      {
        title: 'Vodoinstalterski radovi',
        description: 'Moniranje stanitarija i postavljanje instalacija, bojlera vodokotilca, pregled kanalziacije ciscenje odvoda etc  .',
        image: vodo,
      },

    // Add more service objects as needed
  ];
  const ServiceGrid = () => {
    return (
        <div className="services-container">
      <div className="headerovi">
        <h1>Naše usluge</h1>
      </div>
      <div className="services-grid">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            title={service.title}
            description={service.description}
            image={service.image}
          />
        ))}
         
      </div>
      </div>
    );
  };
  
  export default ServiceGrid;
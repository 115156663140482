
import React from 'react';
import logo from './logo.svg';

import './App.css';
//import { BrowserRouter as Router, Switch, Route, Link, BrowserRouter, Routes } from 'react-router-dom';


import {BrowserRouter, Route, Routes,Link } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './Contact';
import Services from './components/Services';
import Download from './components/Downloadd';
import NavBar from './components/Navbar';
import Footer from "./Footer";
import Toolbar from './toolbar'
import Vesti from './components/vesti';
import 'bootstrap/dist/css/bootstrap.css';

import ScrollToTop from './ScrollToTop';
import { HashRouter } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      
        <div className="background-image"></div>
        
          <header className="App-header">
       
    <Toolbar/>
           
  
      <HashRouter> 
      
      <NavBar  />
      <ScrollToTop />
        <Routes> 
        
          <Route path='/' element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/services' element={<Services/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/vesti' element={<Vesti/>} />
          <Route path='/download' element={<Download/>} />
        
        </Routes>
        <Link className="App-link" to='/services'>
    SVE VRSTE ZAVRŠNIH RADOVA U GRAĐEVINARSTVU</Link>
      </HashRouter>
  
    
       
        <img src={logo} className="App-logo" alt="logo" />
  
    
     
     
     
    
      </header>
   
      

    
  
      <Footer />
      
      </div>

      
      
    
  );
  
}


export default App;

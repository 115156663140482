

import React, { useState } from 'react';


const MainNews = ({ news }) => {
    const [expanded, setExpanded] = useState(false);
  
    const toggleExpanded = () => {
      setExpanded(!expanded);
    };
  
    return (
        <div className="main-news">
          <img src={news.imageUrl} alt="News" className="news-image" />
          <h2>{news.title}</h2>
          {expanded ? <p>{news.fullContent}</p> : <p>{news.content.slice(0, 150)}...</p>}
          {news.fullContent && (
            <button onClick={toggleExpanded}>
              {expanded ? 'Čitaj manje' : 'Čitaj više'}
            </button>
          )}
        </div>
      );
    };
  
  export default MainNews;


import React, { useState } from 'react';
import MainNews from './MainNews';
import SideNews from './SideNews';
import  stiropor from './slikeVesti/stiropor.jpg'
import ministarstvo from './slikeVesti/ministarstvo.jpg';
import masinsko from './slike/masinsko.jpg'
import suva from './slike/suva.jpg'


const Vesti = () => {
    const [mainNews, setMainNews] = useState({
        title: 'Stupile na snagu izmene Zakona o planiranju i izgradnji, brojne novine',
        content: 'Izmene Zakona o planiranju i izgradnji stupile su na snagu',
        fullContent: 'Izmene Zakona o planiranju i izgradnji stupile su na snagu, čime je prestao da važi Zakon o pretvaranju (konverziji) prava korišćenja u pravo svojine na građevinskom zemljištu uz naknadu.',
        imageUrl: ministarstvo
    });
    
      const sideNewsData = [
        {
          title: 'Stupile na snagu izmene Zakona o planiranju i izgradnji, brojne novine',
          content: 'Izmene Zakona o planiranju i izgradnji stupile su na snagu',
          fullContent: 'Izmene Zakona o planiranju i izgradnji stupile su na snagu, čime je prestao da važi Zakon o pretvaranju (konverziji) prava korišćenja u pravo svojine na građevinskom zemljištu uz naknadu.',
          imageUrl: ministarstvo
      },
        {
          title:  'Pravilnik o energetskoj efikasnosti zgrada',
          content: 'Još 01.03.2009. je pokrenuta  široka kampanja u Srbiji',
          fullContent: 'Još 01.03.2009. je pokrenuta  široka kampanja u Srbiji, koja za cilj ima povećanje debljine fasadne termoizolacije u ovdašnjim objektima u izgradnji (ili sanaciji). U Srbiji se termin "fasadna petica" (postavljanje fasadne stiroporne termoizolacione ploče debljine d=5cm) dugi niz godina toliko "ustalio" u govornoj upotrebi da su najširi slojevi stanovništva taj termin počeli da smatraju idealnim rešenjem kada je u pitanju termička izolacija fasade. Istina je međutim potpuno drugačija. Srbija se nalazi u takvoj klimatskoj zoni u Evropi (zone II i III) da je debljina fasadne termoizolacije od samo 5cm sve samo ne optimalna! Sem toga, investicija u fasadnu termoizolaciju od d=5cm je sve samo ne najrentabilnija.',
          imageUrl: stiropor
          
        },
        {
          title: 'Kako odabrati malter za unutrašnje prostore?',
          content: 'Izbor adekvatnog maltera može podići vrednost građevinskog objekta, ',
          fullContent:'Izbor adekvatnog maltera može podići vrednost građevinskog objekta, doneti uštede troškova, smanjiti radne zahteve i sprečiti pojavu oštećenja konstrukcije tokom životnog veka objekta. Da bi se predložio najadekvatniji sistem maltera potrebno je poznavati sve zahteve koji se na njega stavljaju.KAKO ODABRATI MALTER?Od čega je osnova koju ću malterisati? Opeke, porozni beton, stara gradnja, itd.Kakav završni izgled maltera želim da postignem? Želim li potupno ravnu, glaziranu stukturu, nivelisanu, granuliranu, strukturiranu?Kakva izgleda okolina? Uobičajene stambene četvrti, sobe sa povećanim stepenom vlažnosti, prostori pod opterećenjem, itd.',
          imageUrl: masinsko
        },
        {
            title: 'Gipsane ploče u vašem domu ',
            content: 'Stručnjaci savetuju kako da uz jeftine trikove ',
            fullContent:'Stručnjaci savetuju kako da uz jeftine trikove od doma stvorite utočištePrilikom izgradnje novih objekata i modernog uređenja prostora, renoviranja i adaptacije stanova, kuća i poslovnih prostora, najviše vremena, troši se za rušenje postojećih zidova, ali i pravljenje novih..Prilikom izgradnje novih objekata i modernog uređenja prostora, renoviranja i adaptacije stanova, kuća i poslovnih prostora, najviše vremena, troši se za rušenje postojećih zidova, ali i pravljenje novih.Stoga, danas gipsane ploče postaju sve popularnije u dekorisanju enterijera, i osim što će vam ubrzati završetak radova, omogućavaju vam da izbegnete i one prljave radove.Ono što mnogi ne znaju jeste da pored standardnih spuštenih plafona od gipsanih ploča, možete u svom domu izvesti razne oblike zidova koji izgledaju fantastično.Idealno su rešenje za oblaganje neravnih i oštećenih plafonskih površina i za smanjenje visine visokih prostorija zbog uštede energije grejanja. Osim toga, sistemi od gips-kartonskih ploča mogu da izdrže dejstvo požara zahvaljujući hemijskom sastavu materijala.. ',
            imageUrl: suva
          },
        
        // Add more side news articles as needed
      ];
    
      const handleSideNewsClick = (news) => {
        setMainNews(news);
      };
    
      return (
        <div className="vesti">
            <div className="headerovi">
        
        <h1>Najnovije vesti u građevini u Srbiji</h1>
      </div>
      
          
          <div className="news-container">
            <MainNews news={mainNews} />
            <SideNews sideNews={sideNewsData} onSideNewsClick={handleSideNewsClick} />
          </div>
          
        </div>
      );
    };

export default Vesti;
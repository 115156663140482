
import AboutSection from './AboutSection';
import AboutImage from './AboutImage';
import profilna from './slike/profilna.jpg'

function About(){
    return(
    
   <div >
     <div className="headerovi">
        
        <h1>O nama</h1>
      </div>
         <div className="about-us-page">
     
      <div className="containerabout">
        
        <div className="about-section-container">
          <AboutSection
            title="Ovo je priča o nama"
            content="Stvoreni smo sa idejom da na jednom mestu možete naći sve što vam je potrebno da zavrište započetu izgradnju, renoviranje ili adaptaciju novog ili postojećeg objekta. 
            Kvalitet na prvom mestu, sa modernom opremom na vreme i brzo poštujemo rokove.Takodje prodajemo sve vrste materijala i vršimo dostavu na željenu adresu . Sedište kompanije je u Beogradu 
            a imamo i poslovnicu u  Pančevu. 
            
             "
           
          />
          {/* Add more AboutSection components as needed */}
        </div>
        <AboutImage imageSrc={profilna} alt="Team" />
      </div>
      
   
      
    </div>
        
   
      
    </div>
    )
}

export default About
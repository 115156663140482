// AboutImage.js
import React from 'react';

const AboutImage = ({ imageSrc, alt }) => {
  return (
    <div className="about-image">
      <img src={imageSrc} alt={alt} />
    </div>
  );
};

export default AboutImage;
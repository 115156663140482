import React from 'react';

const AboutSection = ({ title, content }) => {
  return (
    <div className="about-section">
      <h2>{title}</h2>
      <p>{content}</p>
    </div>
  );
};

export default AboutSection;
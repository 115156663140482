import React from 'react';


import ServiceCard from "./ServiceCard";
import '../App.css'
import cenovnik from "./slike/cenovnik.jfif"
import atest from "./slike/atest.jfif"
import suva from "./slike/suva.jpg"
import ZOPI from "./slike/ZOPI.jpg"



const services = [
   
    {
      title: 'Cenovnici',
      description: 'Ovde možete preuzeti najnovije cenovnike poznatih srpskih kompanija',
      image: cenovnik,
    },
    {
      title: 'Atesti i Sertifikati',
      description: 'Preuzimanje sertifikata i atesta .',
      image: atest,
    },
    {
        title: 'Prirucnici i tehnike  i sistemi ',
        description: 'Ovde možete naći literaturu koju su za vas spremili građevinski inženjeri a tiče se pravilnog izvođenja i sigurnosti prilikom izgradnje.',
        image: suva,
      },
      {
        title: 'Zakoni i propisi',
        description:'Ukoliko želite da se upozante sa Srpskim zakonima i propisima o izgradnji ovde možete preuzeti najnovije informacije ',
        image: ZOPI,
      },
      

    // Add more service objects as needed
  ];
  const DownloadGird = () => {
    return (
        <div className="services-container">
      <div className="headerovi">
        <h1>Download centar</h1>
      </div>
      <div className="services-grid">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            title={service.title}
            description={service.description}
            image={service.image}
          />
        ))}
        
      </div>
      </div>
    );
  };
  
  export default DownloadGird;



import React, { useState } from 'react';


function ContactForm() {
  const [formData, setFormData] = useState({ 
    name: '', 
    email: '', 
    message: '' 
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('./send-email.php', {
        method: 'POST',
        body: JSON.stringify(formData),
       
      });
      if (response.ok) {
        console.log('Email sent successfully.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

 
  return (
   
    <form className="contact-form" onSubmit={handleSubmit}>
       <div className="headerKontakt">
        <h1>Kontakt</h1>
      </div>
      <input type="text" name="name" placeholder="Name" onChange={handleChange} value={formData.name} />
      <input type="email" name="email" placeholder="Email" onChange={handleChange} value={formData.email} />
      <textarea name="message" placeholder="Message" onChange={handleChange} value={formData.message} />
      <button type="submit">Send Email</button>
    </form>
  );
}


export default ContactForm;
     
      
  
  
 
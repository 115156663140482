
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase  } from '@fortawesome/free-solid-svg-icons';
import logic from './logic.svg';

const Footer = () => {
    const year = new Date().getFullYear();

  
    return <footer className="footer">

        <a href="/"><img src={logic} className="foot-logic" alt="logo" /></a>
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <h5 className="donji"><FontAwesomeIcon icon={ faBriefcase} /> BIG CON d.o.o.</h5>
                <div class="row">
                    <div class="col-6">
                        <ul class="list-unstyled">
                            <li><a href="#">Malterisanje</a></li>
                            <li><a href="#">Termoizolacija i fasade</a></li>
                            <li><a href="#">Suva gradnja</a></li>
                            <li><a href="#">Hidroizolacije</a></li>
                            <li><a href="#">Elekto radovi</a></li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <ul class="list-unstyled">
                            <li><a href="#">Bojenje i zastakljivanje</a></li>
                            <li><a href="#">Ugradnja stolarije</a></li>
                            <li><a href="#">Postavljanje zidnih i podnih obloga</a></li>
                            <li><a href="#">Ostali završni radovi</a></li>
                            <li><a href="#">Vodoinstalterski radovi</a></li>
                        </ul>
                    </div>
                </div>
          
                
            </div>
            <div class="col-md-2">
                <h5 class="text-md-right">Kontaktirajte nas:</h5>
                
            </div>
            <div class="col-md-5">
                <form>
                    <fieldset class="form-group">
                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter email"></input>
                    </fieldset>
                    <fieldset class="form-group">
                        <textarea class="form-control" id="exampleMessage" placeholder="Message"></textarea>
                    </fieldset>
                    <fieldset class="form-group text-xs-right">
                        <button type="button" class="btn btn-primary">Send</button>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</footer>
;
  };
  
  export default Footer;